import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  useTheme
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEmailSignUp } from '@hooks/useEmail';
import { isValidEmail } from '@utils/formatUtils';
import { useSnackbar } from '@hooks/useSnackbar';
import config from '@configFile';
import { getMailingListDisplayNameFromId } from '@utils/mailingLists';

const SignupForm = ({
  customTitle = `Don't Miss a Deal!`,
  customPrompt = `This deal is no longer available. Sign up to our Hot Deals email
list to make sure you don't miss the next one!`,
  mailingList = config.sendGrid.mailingLists.hotDeals
}: {
  customTitle?: string;
  customPrompt?: string;
  mailingList?: string;
}) => {
  const theme = useTheme();
  const displayName = getMailingListDisplayNameFromId(mailingList);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const isDarkMode = theme.palette.mode === 'dark';
  const { mutate: signUp, isLoading } = useEmailSignUp();
  const { showMessage } = useSnackbar();

  const signUpForHotDeals = () => {
    if (!isValidEmail(email)) {
      setEmailError(true);
      return;
    }

    setEmailError(false);

    signUp(
      {
        email,
        selectedEmails: [mailingList]
      },
      {
        onSuccess: () => {
          setEmail('');
          setHasSignedUp(true);
          showMessage(
            `Successfully signed up for ${displayName} mailing list!`
          );
        },
        onError: () => {
          showMessage(
            `There was an error signing up for ${displayName} mailing list. Please try again later.`
          );
        }
      }
    );
  };

  const thankYou = `Thank you for signing up! You'll receive our ${displayName} emails soon!`;
  const buttonText = `Sign Up for ${displayName}`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isDarkMode ? '#2e2e2e' : '#f5f5f5',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: isDarkMode
          ? '0px 4px 12px rgba(0, 0, 0, 0.2)'
          : '0px 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        width: '100%',
        margin: '0 auto',
        marginTop: '48px',
        mb: 4,
        boxSizing: 'border-box'
      }}
    >
      {hasSignedUp ? (
        // Show success message after sign-up
        <Typography
          variant="h6"
          align="center"
          sx={{ color: isDarkMode ? '#fff' : '#000' }}
        >
          {thankYou}
        </Typography>
      ) : (
        <>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{ color: isDarkMode ? '#fff' : '#000' }}
          >
            {customTitle}
          </Typography>

          <Box marginBottom="16px">
            <Typography
              variant="body1"
              align="center"
              gutterBottom
              sx={{ color: isDarkMode ? '#bbb' : '#333' }}
            >
              {customPrompt}
            </Typography>
          </Box>

          <TextField
            label="Enter your email"
            variant="outlined"
            fullWidth
            size="small"
            sx={{
              mb: 2,
              backgroundColor: isDarkMode ? '#424242' : '#fff',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: isDarkMode ? '#777' : '#ccc'
                },
                '&:hover fieldset': {
                  borderColor: isDarkMode ? '#b397ee' : '#3b5998'
                },
                '&.Mui-focused fieldset': {
                  borderColor: isDarkMode ? '#b397ee' : '#3b5998'
                }
              }
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailError}
            helperText={emailError ? 'Please enter a valid email address' : ''}
          />

          {isLoading ? (
            <LoadingButton
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: isDarkMode ? '#b397ee' : '#3b5998',
                color: '#fff',
                '&:hover': {
                  backgroundColor: isDarkMode ? '#9f84d6' : '#365899'
                },
                mb: 1
              }}
              loading
            >
              Signing Up
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: isDarkMode ? '#b397ee' : '#3b5998',
                color: '#fff',
                '&:hover': {
                  backgroundColor: isDarkMode ? '#9f84d6' : '#365899'
                },
                mb: 1
              }}
              onClick={() => signUpForHotDeals()}
            >
              {buttonText}
            </Button>
          )}

          <Typography
            variant="caption"
            align="center"
            sx={{
              color: isDarkMode ? '#bbb' : '#333',
              fontSize: '0.875rem',
              mt: 1
            }}
          >
            Will be used in accordance with our{' '}
            <Link
              href="https://www.jungle.deals/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: isDarkMode ? '#b397ee' : '#3b5998' }}
            >
              Privacy Policy
            </Link>
          </Typography>
        </>
      )}
    </Box>
  );
};

export default SignupForm;

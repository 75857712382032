import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { DealPostType } from '@types';
import {
  formatPrice,
  getCouponLink,
  getProductLink,
  roundTo,
  getPriceLineCalculations,
  addAffiliateTagToLink
} from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';
import Line from './components/Line';
import SeePriceAmazon from './components/SeePriceAmazon';
import SubscribeAndSaveChooser from './components/SubscribeAndSaveChooser';
import ExtraDealReceipt from './components/ExtraDealReceipt';

export type LineConfigType = Array<{
  left: React.ReactNode;
  right: React.ReactNode;
  isTotal?: boolean;
  key: string;
}>;

interface Props {
  receiptDeal: DealPostType;
  tag?: string;
}

export default ({
  receiptDeal: dealData,
  tag = config.AFFILIATE_TAGS.HOW_TO_GET_DEAL
}: Props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [selectedCalculationSS, setSelectedCalculationSS] = useState(
    dealData?.maxSs || 0
  );

  // reset state when dealData changes
  useEffect(() => {
    setSelectedCalculationSS(dealData?.maxSs || 0);
  }, [dealData]);

  const { promoFixed, ss, maxSs } = dealData;

  const productURL = getCouponLink(dealData, tag)
    ? getCouponLink(dealData, tag)
    : getProductLink(dealData.ASIN, tag, dealData?.sLink || '');

  const options: {
    ssOverride?: number;
  } = {};

  if (ss) {
    options.ssOverride = selectedCalculationSS;
  }

  const pricing = getPriceLineCalculations(dealData, options);

  // If final price is 0 or null or undefined, don't show receipt
  // Instead show link to see the price on Amazon. This will show
  // for expired deals.
  if (
    !pricing.finalPrice ||
    pricing.finalPrice === 0 ||
    pricing.finalPrice < 0
  ) {
    return <SeePriceAmazon ASIN={dealData.ASIN} tag={tag} />;
  }

  if (!pricing.hasCoupon && !promoFixed && !ss) {
    // if no subscribe & save, coupons, or promo, don't show receipt
    // since there isn't anything worth showing
    return null;
  }

  const lineConfig: LineConfigType = [];

  lineConfig.push({
    key: 'items',
    left: 'Items:',
    right: `$${pricing.itemsTotal}`
  });

  lineConfig.push({
    key: 'shipping',
    left: 'Shipping & handling:',
    right: '$0.00'
  });

  if (pricing.promoFixed) {
    lineConfig.push({
      key: 'promo',
      left: 'Extra Savings:',
      right: `-$${pricing.promoFixed.toFixed(2)}`
    });
  }

  // if (pricing.finalPrice !== dealData.finalPrice && dealData?.maxSs === selectedCalculationSS) {
  //   console.log(
  //       `❌ ${dealData?.title} Final price mismatch: ${pricing.finalPrice} !== ${dealData.finalPrice}`
  //   );
  // }

  if (pricing.hasCoupon) {
    lineConfig.push({
      key: 'coupon',

      left: (
        <MonetizingLink
          tag={config.AFFILIATE_TAGS.HOW_TO_GET_DEAL}
          clickType="receipt-coupon-link"
          href={addAffiliateTagToLink(
            productURL,
            config.AFFILIATE_TAGS.HOW_TO_GET_DEAL
          )}
          style={{
            fontSize: '12px',
            lineHeight: '16px',
            color: isDarkMode ? '#d0bcfb' : '#0000EE',
            textDecoration: 'underline'
          }}
          sx={{
            '@media (max-width: 540px)': {
              fontSize: '12px !important',
              lineHeight: '16px !important'
            }
          }}
        >
          {pricing.couponSavingsString}
        </MonetizingLink>
      ),
      right: `-$${roundTo(pricing.couponDiscount, 2)}`
    });
  }

  if (ss && pricing.ssDiscount) {
    lineConfig.push({
      key: 'ss',
      left: `Subscribe & Save (${selectedCalculationSS}%):`,
      right: `-$${pricing.ssDiscount.toFixed(2)}`
    });
  }

  let eachPriceStr = '';

  if (
    dealData.qty &&
    dealData.qtyTerm &&
    dealData.qty > 1 &&
    dealData.qtyTerm !== 'each'
  ) {
    eachPriceStr = ` (${formatPrice(pricing.finalPrice / dealData.qty)}/${
      dealData.qtyTerm
    })`;
  }

  lineConfig.push({
    key: 'total',
    left: `Order total${eachPriceStr}:`,
    right: formatPrice(pricing.finalPrice),
    isTotal: true
  });

  return (
    <>
      <Box
        sx={
          ss && pricing.ssDiscount
            ? {
                marginTop: '16px',
                display: 'block',
                backgroundColor: isDarkMode ? '#2f2f2f' : '#eee',
                padding: '10px 3%',
                fontSize: '12px',
                lineHeight: '16px',
                border: isDarkMode
                  ? `1px solid ${theme.palette.mutedBorder}`
                  : 'none'
              }
            : {
                marginTop: '10px',
                padding: '10px 3%',
                fontSize: '12px',
                lineHeight: '16px',
                border: `1px solid ${theme.palette.mutedBorder}`
              }
        }
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            '@media (max-width: 375px)': {
              display: 'block'
            }
          }}
        >
          {ss && pricing.ssDiscount ? (
            <Box
              sx={{
                fontWeight: 700,
                marginBottom: '22px',
                fontSize: '12px',
                lineHeight: '16px',
                width: '50%',
                // if less than 375px, make width 100%
                '@media (max-width: 375px)': {
                  width: '100%',
                  marginBottom: '8px'
                }
              }}
            >
              Subscription summary
            </Box>
          ) : (
            <Box
              sx={{
                marginBottom: '12px',
                marginTop: '4px',
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '24px',
                width: '50%',
                // if less than 375px, make width 100%
                '@media (max-width: 375px)': {
                  width: '100%'
                }
              }}
            >
              Order Summary
            </Box>
          )}
          {ss && maxSs ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '50%',
                marginBottom: '8px',
                // if less than 375px, make width 100%
                '@media (max-width: 375px)': {
                  width: '100%',
                  marginBottom: '8px'
                }
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  margin: '6px 6px 0px 0px !important'
                }}
              >
                Change S&S:
              </Typography>
              <SubscribeAndSaveChooser
                selected={selectedCalculationSS}
                dealData={dealData}
                onChange={(value) => setSelectedCalculationSS(value)}
              />
            </Box>
          ) : null}
        </Box>
        {lineConfig.map((line) => (
          <Line {...line} />
        ))}
      </Box>

      <ExtraDealReceipt receiptDeal={dealData} />
    </>
  );
};

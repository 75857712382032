import { ImageInfo, ImageSegmentsType } from '@types';

const IMAGE_WIDTHS = [200, 400, 600, 800];

export const TARGET_HEIGHT = 190;

export const hasImagesInSegment = (
  imageSegments: ImageSegmentsType,
  index: number,
  width: number,
  isLastBlock: boolean = false
) => {
  const seventyFivePercentOfWidth = width * 0.75;
  if (
    isLastBlock &&
    width > 400 &&
    imageSegments[`width_${width}`]?.[index]?.length === 1 &&
    imageSegments[`width_${width}`]?.[index]?.[0]?.width <
      seventyFivePercentOfWidth
  ) {
    return false;
  }

  if (imageSegments[`width_${width}`]?.[index]?.length > 0) {
    return true;
  }
  return false;
};

// scale the images to the same height of targetHeight and keep the aspect ratio
const getScaledImages = (imageData: ImageInfo[]) => {
  return imageData.map((image) => {
    const aspectRatio = image.width / image.height;
    const newWidth = aspectRatio * TARGET_HEIGHT;
    return {
      ...image,
      width: newWidth,
      height: TARGET_HEIGHT
    };
  });
};

export const sanitizeParagraphs = (postText: string) =>
  (postText || '')
    .split(/\n+/)
    .filter(
      (p) =>
        p !== null &&
        p.trim().length > 0 &&
        p.trim() !== 'Paragraph 1' &&
        p.trim() !== 'Paragraph 1:' &&
        p.trim() !== 'Paragraph 2:' &&
        p.trim() !== 'Paragraph 2'
    );

export const processImageInformation = (schemaData: ImageInfo[]) => {
  if (!schemaData) {
    // If there's no images for the deal just return null
    return null;
  }

  // Create new object to avoid mutating the original
  const imageData = [...schemaData];

  // remove first element if it exists
  // This is because we already use it for the main image
  if (imageData.length > 0) {
    imageData.shift();
  }

  const scaledImages = getScaledImages(imageData);
  return getImageSegments(scaledImages);
};

const getImageSegments = (scaledImages: ImageInfo[]) => {
  const imageSegments: ImageSegmentsType = {};

  for (let i = 0; i < IMAGE_WIDTHS.length; i++) {
    const imagesWidths = [];
    const targetWidth = IMAGE_WIDTHS[i];
    let currentWidth = 0;
    let currentRow = [];
    for (let j = 0; j < scaledImages.length; j++) {
      if (
        currentRow.length === 0 &&
        currentWidth === 0 &&
        scaledImages[j].width > targetWidth
      ) {
        imagesWidths.push([scaledImages[j]]);
      } else if (currentWidth + scaledImages[j].width < targetWidth) {
        currentRow.push(scaledImages[j]);
        currentWidth += scaledImages[j].width;
      } else if (currentWidth + scaledImages[j].width > targetWidth) {
        imagesWidths.push(currentRow);
        currentRow = [];
        currentWidth = scaledImages[j].width;
        currentRow.push(scaledImages[j]);
      }
    }

    if (currentRow.length > 0) {
      imagesWidths.push(currentRow);
    }

    imageSegments[`width_${targetWidth}`] = imagesWidths;
  }

  return imageSegments;
};

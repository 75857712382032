/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import queryClient from '../queryClient';
import { SpecialOfferType } from '../types';

export function useGetAllSpecialOffers(): UseQueryResult<SpecialOfferType[]> {
  const queryFn = async (): Promise<SpecialOfferType[]> => {
    const result = await axios.get(`/api/dashboard/all-special-offers`);
    return result.data;
  };

  return useQuery<SpecialOfferType[], Error>(
    `all-special-offers`,
    () => queryFn(),
    {
      onError: () => {
        console.error('Something went wrong while fetching the special offers');
      }
    }
  );
}

export function useGetSpecialOffers(): UseQueryResult<SpecialOfferType[]> {
  const queryFn = async (): Promise<SpecialOfferType[]> => {
    const result = await axios.get(`/api/special-offers`);
    return result.data;
  };

  return useQuery<SpecialOfferType[], Error>(
    `special-offers`,
    () => queryFn(),
    {
      onError: () => {
        console.error('Something went wrong while fetching the special offers');
      }
    }
  );
}

export function useEditSpecialOffer() {
  interface RequestData {
    body: {
      title: string;
      description: string;
      link: string;
      promoCode: string;
      priority: number;
      discountPercent: number | null;
      discountX: number | null;
      discountOffY: number | null;
      isActive: boolean;
      image: string | null;
      expires: Date | null;
    };
    id: number;
  }

  const mutationFn = async ({ body, id }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/edit-special-offer`, {
      body,
      id
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('special-offers');
      queryClient.invalidateQueries('all-special-offers');
    },
    onError: () => {
      console.error('error editing special offer');
    }
  });
}

export function useDeleteSpecialOffer() {
  const mutationFn = async (id: number): Promise<null> => {
    return axios.post(`/api/dashboard/delete-special-offer`, {
      id
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('special-offers');
      queryClient.invalidateQueries('all-special-offers');
    },
    onError: () => {
      console.error('error deleting special offer');
    }
  });
}

export function useAddSpecialOffer() {
  interface RequestData {
    title: string;
    description: string;
    link: string;
    promoCode: string;
    priority: number;
    discountPercent: number | null;
    discountX: number | null;
    discountOffY: number | null;
    isActive: boolean;
    image: string | null;
    expires: Date | null;
  }

  const mutationFn = async (body: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/add-special-offer`, {
      ...body
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('special-offers');
      queryClient.invalidateQueries('all-special-offers');
    },
    onError: () => {
      console.error('error adding special offer');
    }
  });
}

import React from 'react';
import { Box, CardMedia } from '@mui/material';
import { useTheme } from '@mui/system';
import { DealPostType, ImageSegmentsType } from '@types';
import { getProductLink, parseTitle } from '@utils/index';
import { MonetizingLink } from '../../..';
import { TARGET_HEIGHT, hasImagesInSegment } from '../../utils';

interface ImageSegmentProps {
  index: number;
  imageSegments: ImageSegmentsType;
  ASIN: string;
  tag: string;
  deal: DealPostType;
  isLastBlock: boolean;
}

const ImageSegment = ({
  index,
  imageSegments,
  ASIN,
  tag,
  deal,
  isLastBlock
}: ImageSegmentProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  if (
    !imageSegments ||
    Object.keys(imageSegments).length === 0 ||
    (imageSegments[`width_${200}`].length === 0 &&
      imageSegments[`width_${400}`].length === 0 &&
      imageSegments[`width_${600}`].length === 0 &&
      imageSegments[`width_${800}`].length === 0)
  ) {
    return null;
  }

  const alt = `View ${parseTitle(deal.title)} product details on Amazon`;

  const additionalImageCSS: React.CSSProperties = isDarkMode
    ? {
        opacity: 0.9
      }
    : {};

  return (
    <Box
      sx={{
        containerType: 'inline-size'
      }}
    >
      <MonetizingLink
        href={getProductLink(ASIN, tag, deal?.sLink || '')}
        clickType="post-text-image"
        tag={tag}
      >
        <Box
          data-test-id="image-segment-200"
          marginTop="16px"
          sx={{
            display: 'none',
            '@container (max-width: 400px)': {
              display: 'flex'
            }
          }}
        >
          {imageSegments[`width_${200}`]?.[index]?.map((image) => {
            const additionalStyles = {
              width: 'initial',
              margin: '0 auto'
            };
            return (
              <CardMedia
                component="img"
                height={TARGET_HEIGHT}
                image={image.image}
                alt={alt}
                style={{
                  // objectFit: 'contain',
                  display: 'inline-block',
                  height: '100%',
                  border: '1px solid #e0e0e0',
                  boxSizing: 'border-box',
                  maxHeight: '320px',
                  ...additionalStyles,
                  ...additionalImageCSS
                }}
                key={`img-${image.image}`}
              />
            );
          })}
        </Box>

        <Box
          data-test-id="image-segment-400"
          marginTop="16px"
          sx={{
            display: 'none',
            '@container (min-width: 400px) and (max-width: 600px)': {
              display: 'flex'
            }
          }}
        >
          {imageSegments[`width_${400}`]?.[index]?.map((image) => (
            <CardMedia
              component="img"
              height={TARGET_HEIGHT}
              image={image.image}
              alt={alt}
              style={{
                objectFit:
                  (imageSegments[`width_${400}`]?.[index] || []).length === 1
                    ? 'cover'
                    : 'contain',
                display: 'inline-block',
                ...additionalImageCSS
              }}
              key={`img-${image.image}`}
            />
          ))}
        </Box>
        <Box
          data-test-id="image-segment-600"
          marginTop="16px"
          sx={{
            display: 'none',
            '@container (min-width: 600px) and (max-width: 800px)': {
              display: hasImagesInSegment(
                imageSegments,
                index,
                600,
                isLastBlock
              )
                ? 'flex'
                : 'none'
            },
            ...additionalImageCSS
          }}
        >
          {imageSegments[`width_${600}`]?.[index]?.map((image) => (
            <CardMedia
              component="img"
              height={TARGET_HEIGHT}
              image={image.image}
              alt={alt}
              style={{
                objectFit: 'contain',
                display: 'inline',
                ...additionalImageCSS
              }}
              key={`img-${image.image}`}
            />
          ))}
        </Box>
        <Box
          marginTop="16px"
          data-test-id="image-segment-800"
          sx={{
            display: 'none',
            '@container (min-width: 800px)': {
              display: hasImagesInSegment(
                imageSegments,
                index,
                800,
                isLastBlock
              )
                ? 'flex'
                : 'none'
            },
            ...additionalImageCSS
          }}
        >
          {imageSegments[`width_${800}`]?.[index]?.map((image) => (
            <CardMedia
              component="img"
              height={TARGET_HEIGHT}
              image={image.image}
              alt={alt}
              style={{
                objectFit: 'contain',
                display: 'inline',
                ...additionalImageCSS
              }}
              key={`img-${image.image}`}
            />
          ))}
        </Box>
      </MonetizingLink>
    </Box>
  );
};

export default ImageSegment;

import React from 'react';
import { Typography, Card, CardMedia, CardContent } from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { daysRemaining, addAffiliateTagToLink } from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';
import { SpecialOfferType } from '@types';

const CurrentSpecialOfferCard = ({
  so,
  isLastOffer = false,
  small = false,
  tag = config.AFFILIATE_TAGS.JD_SPECIAL_OFFERS,
  elevation = 0
}: {
  so: SpecialOfferType;
  isLastOffer?: boolean;
  tag?: string;
  small?: boolean;
  elevation?: number;
}) => {
  const link = addAffiliateTagToLink(so.link, tag);
  const theme = useTheme();
  return (
    <Card
      key={link}
      sx={{
        margin: '12px auto 0 auto',
        borderBottom: isLastOffer ? 'none' : '1px solid #c1c4cc',
        borderRadius: '0px',
        paddingTop: '10px'
      }}
      elevation={elevation}
    >
      <MonetizingLink
        href={link}
        style={{
          display: 'block'
        }}
        tag={config.AFFILIATE_TAGS.JDB_DAILY}
        clickType="deal-post-card-image"
        aria-label={`Link to see ${so.title} at Amazon`}
      >
        <CardMedia
          component="img"
          image={so.image?.replace('http:', 'https:')}
          alt=""
          sx={{
            maxWidth: small ? '100%' : '92%',
            maxHeight: '206px',
            display: 'block',
            margin: small ? '0px' : '1em auto 12px auto',
            textAlign: 'center'
          }}
        />
      </MonetizingLink>
      <CardContent
        sx={{
          marginTop: '0px'
        }}
      >
        <MonetizingLink
          href={link}
          tag={config?.AFFILIATE_TAGS?.JDB_DAILY}
          clickType="promotion-page"
          style={{
            display: 'block',
            fontWeight: 500,
            color: theme.palette.linkColor,
            textDecoration: 'none',
            transition: 'color .5s'
          }}
          aria-label={`Link to see ${so.title} at Amazon`}
        >
          <Typography
            variant={small ? 'subtitle1' : 'h6'}
            component="div"
            align="left"
          >
            <strong>{`${so.title}${
              so.promoCode ? ` — (Use code ${so.promoCode} at checkout)` : ''
            }`}</strong>
            <strong
              style={{
                color: '#AD0000'
              }}
            >{`${so.expires ? ` [${daysRemaining(so.expires)}]` : ''}`}</strong>
          </Typography>
        </MonetizingLink>
        {so.description && (
          <Typography
            variant="body2"
            align="left"
            color="textSecondary"
            sx={{
              whiteSpace: 'pre-wrap',
              marginTop: '8px'
            }}
          >
            {so.description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default CurrentSpecialOfferCard;

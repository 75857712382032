import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import {
  formatPrice,
  getProductLink,
  getCouponLinkFromCode
} from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';
import { DealPostType } from '@types';
import {
  standardTextStyle,
  standardLinkStyle as standardLinkStyleBase
} from '@utils/styles';
import config from '@configFile';

const HowToGetDealText = ({
  deal,
  tag = config.AFFILIATE_TAGS.PRODUCT_PAGE
}: {
  deal: DealPostType;
  tag?: string;
}) => {
  const theme = useTheme();
  const link = getProductLink(deal?.ASIN, tag);
  const cancelAnytime = `We recomend setting your delivery frequency to 6 months giving you plenty of time to cancel.`;
  const SSlink = `https://www.amazon.com/Subscribe-Save/b?ie=UTF8&node=5856181011&tag=${tag}`;
  const manageSSPage = `https://www.amazon.com/gp/subscribe-and-save/manager/viewsubscriptions?ref_=ya_d_l_subscribe_save&tag=${tag}`;

  // no coupon or subscribe & save
  if (
    !deal?.couponFixed &&
    !deal?.couponPercentage &&
    !deal?.ss &&
    !deal?.maxSs
  ) {
    return null;
  }

  const standardLinkStyle = {
    ...standardLinkStyleBase,
    color: theme.palette.linkColor
  };

  // subscribe & save
  if (
    deal?.ss &&
    deal?.maxSs &&
    !deal?.couponFixed &&
    !deal?.couponPercentage
  ) {
    return (
      <Box mb={3}>
        <Typography sx={standardTextStyle}>
          Choose the{` `}
          <MonetizingLink
            clickType="product-page-ss"
            target="_blank"
            href={SSlink}
            tag={tag}
            sx={{
              ...standardLinkStyle,
              fontWeight: 'bold'
            }}
          >
            Subscribe & Save
          </MonetizingLink>
          {` `}buying option to save up to an{' '}
          <strong>extra {deal?.maxSs}%</strong> when you have 5 or more items
          shipping in a month, cancel anytime. {cancelAnytime}
          {` `}
          <MonetizingLink
            clickType="product-page-ss"
            target="_blank"
            href={manageSSPage}
            tag={tag}
            sx={{
              ...standardLinkStyle
            }}
          >
            Modify your Subscribe & Save
          </MonetizingLink>
          {` `}deliveries at any time.
        </Typography>
      </Box>
    );
  }

  const couponAmount = deal?.couponFixed
    ? `${formatPrice(deal?.couponFixed)}`
    : `${deal?.couponPercentage}%`;

  const couponLink = deal?.promoCode
    ? getCouponLinkFromCode(deal?.promoCode, tag)
    : link;

  // coupon
  if (
    (deal?.couponFixed || deal?.couponPercentage) &&
    !deal?.ss &&
    !deal?.maxSs
  ) {
    return (
      <Box mb={3}>
        <Typography sx={standardTextStyle}>
          <MonetizingLink
            clickType="product-page-coupon"
            target="_blank"
            href={link}
            tag={tag}
            sx={{
              ...standardLinkStyle
            }}
          >
            Go to the product page
          </MonetizingLink>
          {` `}and{` `}
          <MonetizingLink
            clickType="product-page-coupon-click"
            target="_blank"
            href={couponLink}
            tag={tag}
            sx={{
              ...standardLinkStyle
            }}
          >
            click to clip the <strong>{couponAmount} coupon</strong>
          </MonetizingLink>
          {` `}which will be applied at checkout.
        </Typography>
      </Box>
    );
  }

  // coupon and subscribe & save
  if (
    (deal?.couponFixed || deal?.couponPercentage) &&
    deal?.ss &&
    deal?.maxSs
  ) {
    return (
      <Box mb={3}>
        <Typography sx={standardTextStyle}>
          <MonetizingLink
            clickType="product-page-coupon"
            target="_blank"
            href={link}
            tag={tag}
            sx={{
              ...standardLinkStyle
            }}
          >
            Go to the product page
          </MonetizingLink>
          {` `}and{` `}
          <MonetizingLink
            clickType="product-page-coupon-click"
            target="_blank"
            href={couponLink}
            tag={tag}
            sx={{
              ...standardLinkStyle
            }}
          >
            click to clip the <strong>{couponAmount} coupon</strong>
          </MonetizingLink>
          {` `}which will be applied at checkout. Choose{` `}
          <MonetizingLink
            clickType="product-page-ss"
            target="_blank"
            href={SSlink}
            tag={tag}
            sx={{
              ...standardLinkStyle,
              fontWeight: 'bold'
            }}
          >
            Subscribe & Save
          </MonetizingLink>
          {` `}to save up to an <strong>extra {deal?.maxSs}%</strong> when you
          have 5 or more items shipping in a month, cancel anytime.{' '}
          {cancelAnytime}
          {` `}
          <MonetizingLink
            clickType="product-page-ss"
            target="_blank"
            href={manageSSPage}
            tag={tag}
            sx={{
              ...standardLinkStyle
            }}
          >
            Modify your Subscribe & Save
          </MonetizingLink>
          {` `}deliveries at any time.
        </Typography>
      </Box>
    );
  }

  return null;
};

export default HowToGetDealText;

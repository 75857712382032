import React from 'react';
import { Box } from '@mui/material';
import config from '@configFile';
import MonetizingLink from '@components/MonetizingLink';
import { getProductLink } from '@utils/index';

const SeePriceAmazon = ({
  ASIN,
  tag = config.AFFILIATE_TAGS.HOW_TO_GET_DEAL
}: {
  ASIN: string;
  tag?: string;
}) => {
  return (
    <>
      <Box
        sx={{
          padding: '16px'
        }}
        display="flex"
        textAlign="center"
      >
        <MonetizingLink
          href={getProductLink(ASIN, tag)}
          target="_blank"
          tag={tag}
          clickType="see-price-amazon"
          underline="always"
        >
          {`See price on Amazon >>>`}
        </MonetizingLink>
      </Box>
    </>
  );
};

export default SeePriceAmazon;

export const getScaledImage = (
  width: number,
  height: number,
  targetSize: number
) => {
  if (!width || !height || width === 0 || height === 0) {
    return {
      width: targetSize,
      height: targetSize
    };
  }
  const aspectRatio = width / height;

  // Calculate dimensions based on the target height
  const heightBasedWidth = aspectRatio * targetSize;
  const heightBasedHeight = targetSize;

  // Calculate dimensions based on the target width
  const widthBasedWidth = targetSize;
  const widthBasedHeight = targetSize / aspectRatio;

  // Choose the smaller size that fits within the target constraints
  let finalWidth;
  let finalHeight;

  if (heightBasedWidth <= targetSize && heightBasedHeight <= targetSize) {
    finalWidth = heightBasedWidth;
    finalHeight = heightBasedHeight;
  } else {
    finalWidth = widthBasedWidth;
    finalHeight = widthBasedHeight;
  }

  return {
    width: finalWidth,
    height: finalHeight
  };
};

import * as React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';

interface Props {
  children: React.ReactElement;
  scrollToTop?: () => void;
}

const ScrollTopHelper = (props: Props) => {
  const {
    children,
    scrollToTop = () => {
      window?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    // @ts-ignore
    target: typeof window !== 'undefined' ? window : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = () => {
    scrollToTop();
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 999
        }}
      >
        {children}
      </Box>
    </Fade>
  );
};

const ScrollTop = ({ scrollToTop }: { scrollToTop?: () => void }) => {
  return (
    <ScrollTopHelper scrollToTop={scrollToTop}>
      <Fab size="small" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollTopHelper>
  );
};

export default ScrollTop;
